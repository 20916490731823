import { ApplicationEnvironment } from './environment.model';
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

export const environment = new ApplicationEnvironment({
    apiServerUrl: 'https://api.refactor.idocs.kz',
    identityServerUrl: 'https://identity.refactor.idocs.kz',
    corporateServerUrl: 'https://site.api.refactor.idocs.kz',
    authenticationHost: 'https://account.refactor.idocs.kz',
    isProductionMode: false,
    environmentName: 'refactor',
});

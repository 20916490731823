import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';

export class ApplicationEnvironment extends BaseModel<ApplicationEnvironment> {
    GetDefaultValue(): StrictBaseModelPart<ApplicationEnvironment, never> {
        return {
            identityServerUrl: '',
            apiServerUrl: '',
            corporateServerUrl: '',
            isProductionMode: false,
            authenticationHost: 'https://localhost:4201',
            documentHost: 'https://localhost',
            pdfViewerLicenceKey: 'AawCd4edafM28tdDPInx',
            environmentName: 'local',
        };
    }

    protected BASE_TYPE = ApplicationEnvironment;

    environmentName!: 'local' | 'refactor' | 'production' | 'beta';
    identityServerUrl!: string;
    apiServerUrl!: string;
    isProductionMode!: boolean;
    corporateServerUrl!: string;
    authenticationHost!: string;
    documentHost!: string;
    pdfViewerLicenceKey!: string;
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { EmailSigningAppRoutingModule } from './email-signing-app-routing.module';
import { EmailSigningAppComponent } from './email-signing-app.component';
import { NgxMaskModule } from 'ngx-mask';
import { NotificationModule } from '@idocs/shared-ui/notification';
import { NgAuthorizeModule } from 'ng-authorize';
import { IdentityConnectorModule } from '@idocskz/identity-connector';
import { EMAIL_SIGNING_POLICIES } from './email-signing-auth/email-signing.auth-policies';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { BusinessServerApiModule } from '@idocs/api/business/services/BusinessServerApi.module';
import { SharedAuthModule } from '@idocs/shared-logic';
import { AppIconsModule } from '@idocs/shared-ui/app-icons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { EmailSigningTokenObtainer } from './email-signing-auth/email-signing.token-obtainer';
import { SharedAccountModel } from '@idocs/shared-logic/shared-auth';
import { EmailSigningAuthenticationHandler } from './email-signing-auth/email-signing.authentication-handler';
import { BladeModule } from '@idocs/shared-ui/blade';
import { NgNcalayerModule } from 'ng-ncalayer';
import { ErrorPagesModule } from '@idocs/shared-ui/error-pages';
import { ReminderService } from './services/reminder.service';
import { MatDialogModule } from '@angular/material/dialog';
import { EMAIL_SIGNING_ICONS } from './utils/icons';

@NgModule({
    declarations: [EmailSigningAppComponent],
    imports: [
        BrowserModule,
        EmailSigningAppRoutingModule,
        BrowserAnimationsModule,
        IdentityConnectorModule.client({
            accountClass: () => SharedAccountModel,
            identityUrl: environment.authenticationHost,
            authenticationHandler: EmailSigningAuthenticationHandler,
        }),
        MatNativeDateModule,
        HttpClientModule,
        NgxMaskModule.forRoot(),
        NotificationModule.forRoot(),
        NgAuthorizeModule.forRoot(EMAIL_SIGNING_POLICIES),
        BusinessServerApiModule.forRoot(`${environment.apiServerUrl}`),
        SharedAuthModule.forRoot({
            tokenObtainer: EmailSigningTokenObtainer,
        }),
        AppIconsModule.forRoot(EMAIL_SIGNING_ICONS),
        BladeModule.forRoot(),
        NgNcalayerModule.forRoot(),
        ErrorPagesModule.forRoot({
            url: 'https://idocs.kz',
            sameOrigin: false,
        }),
        MatDialogModule,
    ],
    providers: [ReminderService],
    bootstrap: [EmailSigningAppComponent],
})
export class EmailSigningAppModule {}

import { IconDefinition } from '@idocs/shared-ui/app-icons';

const icons = ['idocs-x-astyk-trans'];

export const EMAIL_SIGNING_ICONS = icons.reduce((defenitions, cur) => {
    return {
        ...defenitions,
        [cur]: {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            body: require(`!!raw-loader?!./../../assets/icons/${cur}.svg`)
                .default,
        },
    };
}, {} as Record<string, IconDefinition>);

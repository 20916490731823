import { createPolicy } from 'ng-authorize';
import { AuthService } from './auth.service';
import { ErrorPagesService } from '@idocs/shared-ui/error-pages';
import { ActivatedRoute } from '@angular/router';

export namespace EmailSigningPolicies {
    export const Authenticated = createPolicy(
        'email-signing.policies.Authenticated',
        async (
            authService: AuthService,
            _: ErrorPagesService,
            __: ActivatedRoute
        ) => authService.isAuthenticated(),
        [AuthService, ErrorPagesService, ActivatedRoute]
    );
}

export const EMAIL_SIGNING_POLICIES = [EmailSigningPolicies.Authenticated];

import { Component } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';

@Component({
    selector: 'email-signing-app-root',
    templateUrl: './email-signing-app.component.html',
    styleUrls: ['./email-signing-app.component.scss'],
})
export class EmailSigningAppComponent extends BaseComponent {
    title = 'email-signing';
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteBuilder } from 'ng-utils';

const routes: Routes = [
    RouteBuilder.withPath('')
        .withLoadChildren(() => import('./pages/email-signing-pages.module').then((m) => m.EmailSigningPagesModule))
        .build(),
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    anchorScrolling: 'enabled',
    urlUpdateStrategy: 'eager'
}),
    ],
    exports: [RouterModule],
})
export class EmailSigningAppRoutingModule {}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';

export class ErrorPageConfig extends BaseModel<ErrorPageConfig> {
    protected BASE_TYPE = ErrorPageConfig;
    code!: string;
    header!: string;
    content!: string;

    GetDefaultValue(): StrictBaseModelPart<ErrorPageConfig, never> {
        return {
            code: '000',
            header: 'Что-то здесь пошло не так…',
            content:
                'Возможно, Вы перешли по нерабочей ссылке или попытались открыть несуществующий документ или страницу, или у Вас нет прав доступа к данному документу или странице.',
        };
    }
}

@Injectable({ providedIn: 'root' })
export class ErrorPagesService {
    constructor(private router: Router) {}

    async handle(error: ErrorPageConfig | string) {
        if (error instanceof ErrorPageConfig) {
            return await this.router.navigate([
                '/error',
                error.ToPlainObject(),
            ]);
        } else {
            return await this.router.navigate(['/error', error]);
        }
    }
}

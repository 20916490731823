import { Inject, Injectable } from '@angular/core';
import {
    IDENTITY_URL,
    IdentityConnectorClient,
} from '@idocskz/identity-connector';
import { SharedAccountModel } from '@idocs/shared-logic';

@Injectable({ providedIn: 'root' })
export class AuthService {
    connectorUrl: string;

    constructor(
        private identityConnectorClient: IdentityConnectorClient<SharedAccountModel>,
        @Inject(IDENTITY_URL) private identityUrl: string
    ) {
        this.connectorUrl = `${identityUrl}/identity-connector`;
    }

    isAuthenticated(): boolean {
        return (
            this.tokenResult != null
            // &&
            // moment().isBefore(moment(this.tokenResult.ExpirationDate))
        );
    }

    public get tokenResult() {
        return this.identityConnectorClient.selectedAccount?.tokenResult;
    }

    switchAccount() {
        this.identityConnectorClient.authenticate({ notRegistered: true });
    }

    authenticate(companyId: string) {
        this.identityConnectorClient.authenticate({
            accountId:
                this.identityConnectorClient.selectedAccount?.id ?? companyId,
            data: {
                companyId: companyId,
            },
            notRegistered: true,
        });
    }
}

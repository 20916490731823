import { Injectable } from '@angular/core';
import { SynchronizationConfiguration, Synchronize } from '@dev-stream/utils';

export const REMINDER_SERVICE_SCONFIG = new SynchronizationConfiguration({
    prefix: 'sign.ReminderService',
    storage: sessionStorage,
});

@Injectable({ providedIn: 'root' })
export class ReminderService {
    @Synchronize(REMINDER_SERVICE_SCONFIG)
    public linkId?: string;

    @Synchronize(REMINDER_SERVICE_SCONFIG)
    public companyId?: string;
}
